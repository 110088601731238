import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Button from '@/components/ui/Button/Button'
import NewPaperIcon from '@/components/ui/Icons/NewPaperIcon/NewPaperIcon'
import { ProductType, ShowGiveawayAudience } from '@/network/graphql/types.generated'

import GiveawayDetailsForm from './GiveawayDetailsForm'

import type { ExtraProductFormOptions, ProductImage, ProductInputData } from '../types'
import type { ChangeEvent } from 'react'

import './GiveawayForm.scss'

type InputChangeEvent = ChangeEvent<HTMLInputElement>
type TextareaChangeEvent = ChangeEvent<HTMLTextAreaElement>

type CreateGiveawayProps = {
  isLoading: boolean
  isShowBroadcasting: boolean
  onSubmit: (input: ProductInputData & ExtraProductFormOptions) => void
  isEditMode?: boolean
  initialValues?: ProductInputData
  productId: string
}

export const CreateGiveaway = (props: CreateGiveawayProps) => {
  const { isLoading, onSubmit, isEditMode = false, isShowBroadcasting, initialValues, productId } = props
  const { t } = useTranslation()

  const [step, setStep] = useState<number>(1)

  const [audience, setAudience] = useState<ShowGiveawayAudience | undefined>(
    initialValues?.giveawayAudience ?? undefined
  )
  const [international, setInternational] = useState<boolean>(initialValues?.isGiveawayOpenToInternational || false)
  const [createAndLaunch, setCreateAndLaunch] = useState<boolean>(false)
  const [createMore, setCreateMore] = useState<boolean>(false)

  const [images, setImages] = useState<ProductImage[]>(initialValues?.images || [])
  const [title, setTitle] = useState<string>(initialValues?.title || 'Giveaway')
  const [description, setDescription] = useState<string>(initialValues?.description || '')
  const [availableQuantity, setAvailableQuantity] = useState<number>(initialValues?.availableQuantity || 1)

  const handleImagesChange = useCallback((newImages: ProductImage[]) => setImages(newImages), [])
  const handleTitleChange = useCallback((e: InputChangeEvent) => setTitle(e.target.value), [])
  const handleDescriptionChange = useCallback((e: TextareaChangeEvent) => setDescription(e.target.value), [])
  const handleAvailableQuantityChange = useCallback(
    (e: InputChangeEvent) => setAvailableQuantity(Number(e.target.value)),
    []
  )

  const handleSubmit = useCallback(async () => {
    await onSubmit({
      availableQuantity,
      buyNowPrice: undefined,
      category: initialValues?.category || null,
      description,
      giveawayAudience: audience,
      images,
      isPreOrder: initialValues?.isPreOrder || false,
      price: initialValues?.price || undefined,
      salesType: initialValues?.salesType || null,
      startingPrice: undefined,
      title,
      type: ProductType.Giveaway,
      isGiveawayOpenToInternational: international,
      createAndLaunch,
      createMore,
    })
  }, [
    onSubmit,
    audience,
    international,
    createAndLaunch,
    createMore,
    initialValues,
    availableQuantity,
    description,
    images,
    title,
  ])

  const handleNext = useCallback(() => {
    setStep(2)
  }, [])

  const handlePrev = useCallback(() => {
    setStep(1)
  }, [])
  return (
    <>
      {step === 1 ? (
        <div className="giveaway-form">
          <div className="giveaway-form-content">
            <div>
              <h2 className="giveaway-form-title">{t('giveawaySettings')}</h2>
              <div className="giveaway-form-audience">
                <h3 className="giveaway-form-audience-title">{t('giveawayFormAudience')}</h3>
                <div
                  className={`giveaway-form-audience-item ${audience === ShowGiveawayAudience.PresentUsers && 'giveaway-form-audience-item-selected'}`}
                  onClick={() => setAudience(ShowGiveawayAudience.PresentUsers)}
                >
                  <div className="giveaway-form-audience-item-icon">👀</div>

                  <div className="giveaway-form-audience-item-content">
                    <h3>{t('audienceOptionPresentUserTitle')}</h3>
                    <p>{t('audienceOptionPresentUserDetails')}</p>
                  </div>
                </div>

                <div
                  className={`giveaway-form-audience-item ${audience === ShowGiveawayAudience.Buyers && 'giveaway-form-audience-item-selected'}`}
                  onClick={() => setAudience(ShowGiveawayAudience.Buyers)}
                >
                  <div className="giveaway-form-audience-item-icon">🛍️</div>

                  <div className="giveaway-form-audience-item-content">
                    <h3>{t('audienceOptionBuyersTitle')}</h3>
                    <p>{t('audienceOptionBuyersDetails')}</p>
                  </div>
                </div>

                <div
                  className={`giveaway-form-audience-item ${audience === ShowGiveawayAudience.BuyersOrders && 'giveaway-form-audience-item-selected'}`}
                  onClick={() => setAudience(ShowGiveawayAudience.BuyersOrders)}
                >
                  <div className="giveaway-form-audience-item-icon">🔄</div>

                  <div className="giveaway-form-audience-item-content">
                    <h3>{t('audienceOptionBuyersOrdersTitle')}</h3>
                    <p>{t('audienceOptionBuyersOrdersDetails')}</p>
                  </div>
                </div>

                <div
                  className={`giveaway-form-audience-item ${audience === ShowGiveawayAudience.SharersOpeners && 'giveaway-form-audience-item-selected'}`}
                  onClick={() => setAudience(ShowGiveawayAudience.SharersOpeners)}
                >
                  <div className="giveaway-form-audience-item-icon">📣</div>

                  <div className="giveaway-form-audience-item-content">
                    <h3>{t('audienceOptionSharersOpenersTitle')}</h3>
                    <p>{t('audienceOptionSharersOpenerDetails')}</p>
                  </div>
                </div>

                <div
                  className={`giveaway-form-audience-item ${audience === ShowGiveawayAudience.Followers && 'giveaway-form-audience-item-selected'}`}
                  onClick={() => setAudience(ShowGiveawayAudience.Followers)}
                >
                  <div className="giveaway-form-audience-item-icon">🌟</div>

                  <div className="giveaway-form-audience-item-content">
                    <h3>{t('audienceOptionFollowersTitle')}</h3>
                    <p>{t('audienceOptionFollowersDetails')}</p>
                  </div>
                </div>
              </div>

              <div className="giveaway-form-international">
                <Field
                  checked={international}
                  label={t('giveawayLaunchModalCriteriaInternationalParticipants')}
                  name="international"
                  type="checkbox"
                  onClick={() => setInternational((prev) => !prev)}
                />
              </div>
            </div>

            <div className="giveaway-form-details">
              <h3 className="giveaway-form-title">{t('giveawayFormDetails')}</h3>
              {title !== 'Giveaway' || images.length > 0 ? (
                <Button
                  className="giveaway-form-details-button"
                  disabled={audience === undefined}
                  label={
                    <>
                      <span>
                        {t('giveawayFormDetailsFieldUpdate')} ({title})
                      </span>
                      <NewPaperIcon />
                    </>
                  }
                  onClick={handleNext}
                />
              ) : (
                <Button
                  className="giveaway-form-details-button"
                  disabled={audience === undefined}
                  label={
                    <>
                      <span>{t('giveawayFormDetailsField')}</span> <NewPaperIcon />
                    </>
                  }
                  onClick={handleNext}
                />
              )}
            </div>
          </div>

          <div className="giveaway-form-actions">
            <div className="giveaway-form-actions-options">
              <Field
                checked={createAndLaunch}
                data-tooltip={!isShowBroadcasting ? t('productFormLaunchProductAfterCreateDisabledTitle') : undefined}
                data-tooltip-position="bottom left"
                disabled={!isShowBroadcasting || isEditMode}
                id="create-and-launch"
                label={t('productFormLaunchProductAfterCreateLabel')}
                name="create-and-launch"
                type="checkbox"
                onChange={() => setCreateAndLaunch((prev) => !prev)}
              />

              <Field
                checked={createMore}
                disabled={isEditMode}
                id="create-more"
                label={t('productFormCreateMoreLabel')}
                name="create-more"
                type="checkbox"
                onChange={() => setCreateMore((prev) => !prev)}
              />
            </div>

            <Button
              className="primary"
              disabled={audience === undefined}
              isLoading={isLoading}
              label={isEditMode ? t('giveawayFormUpdateButton') : t('giveawayFormCreateButton')}
              onClick={handleSubmit}
            />
          </div>
        </div>
      ) : (
        <GiveawayDetailsForm
          availableQuantity={availableQuantity}
          description={description}
          handleAvailableQuantityChange={handleAvailableQuantityChange}
          handleDescriptionChange={handleDescriptionChange}
          handleImagesChange={handleImagesChange}
          handleTitleChange={handleTitleChange}
          images={images}
          productId={productId}
          title={title}
          onBack={handlePrev}
        />
      )}
    </>
  )
}
