import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft } from 'react-icons/fa6'

import ProductAvailableQuantityField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductAvailableQuantityField/ProductAvailableQuantityField'
import ProductDescriptionField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductDescriptionField/ProductDescriptionField'
import ProductImagesField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductImagesField/ProductImagesField'
import ProductTitleField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductTitleField/ProductTitleField'
import { PRODUCT_FORM_ACTION } from '@/components/CreateOrEditProduct/types'
import Form from '@/components/Form/Form'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import { useUser } from '@/contexts/user/User.context'

import type { ProductImage } from '@/components/CreateOrEditProduct/types'

import '../CreateOrEditProductForm/CreateOrEditProductForm.scss'

type InputChangeEvent = ChangeEvent<HTMLInputElement>
type TextareaChangeEvent = ChangeEvent<HTMLTextAreaElement>

type CreateOrEditProductFormProps = {
  productId: string
  onBack: () => void
  images: ProductImage[]
  title: string
  description: string
  availableQuantity: number
  handleImagesChange: (newImages: ProductImage[]) => void
  handleTitleChange: (e: InputChangeEvent) => void
  handleDescriptionChange: (e: TextareaChangeEvent) => void
  handleAvailableQuantityChange: (e: InputChangeEvent) => void
}

const GiveawayDetailsForm = (props: CreateOrEditProductFormProps) => {
  const { t } = useTranslation()
  const {
    productId,
    images,
    title,
    description,
    availableQuantity,
    handleImagesChange,
    handleTitleChange,
    handleDescriptionChange,
    handleAvailableQuantityChange,
    onBack,
  } = props

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { isInventoryInitialized } = sellerConfig || {}

  const urlParams = new URLSearchParams(location.search)
  const action = (urlParams.get('action') as PRODUCT_FORM_ACTION) || undefined
  const isDuplicateAction = action === PRODUCT_FORM_ACTION.DUPLICATE
  const isEditMode = Boolean(productId && productId !== 'new' && !isDuplicateAction)

  return (
    <>
      <Button
        className="giveaway-details-back-button"
        icon={<FaArrowLeft />}
        label={t('commonBack')}
        onClick={onBack}
      />
      <Form className="giveaway-details-form" id="create-edit-product-form">
        <ProductImagesField disabled={!isInventoryInitialized} value={images} onUpdateImages={handleImagesChange} />

        <ProductTitleField disabled={!isInventoryInitialized} value={title} onChange={handleTitleChange} />
        <ProductDescriptionField
          disabled={!isInventoryInitialized}
          value={description}
          onChange={handleDescriptionChange}
        />
        <ProductAvailableQuantityField
          disabled={!isInventoryInitialized}
          min={isEditMode ? 0 : 1}
          value={availableQuantity}
          onChange={handleAvailableQuantityChange}
        />

        <div className="actions">
          {!isInventoryInitialized && (
            <Alert className="product-form-alert" type="warning">
              {t('productFormAlertInventory')}
            </Alert>
          )}
        </div>
      </Form>
    </>
  )
}

export default GiveawayDetailsForm
