import { memo, useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  isProductFromFashionCategory,
  isProductFromSneakersCategory,
} from '@/components/CreateOrEditProduct/CreateOrEditProduct.helpers'
import Field from '@/components/Form/Field/Field'

type ProductTitleFieldProps = {
  value?: string
  category?: string
  placeholder?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const ProductTitleField = memo((props: ProductTitleFieldProps) => {
  const { value: title, onChange, category, disabled = false } = props
  const { t } = useTranslation()

  const isFashionProduct = category ? isProductFromFashionCategory(category) : false
  const isSneakersProduct = category ? isProductFromSneakersCategory(category) : false

  const placeholder = useMemo(() => {
    return isFashionProduct
      ? t('productFormFashionTitlePlaceholder')
      : isSneakersProduct
        ? t('productFormSneakersTitlePlaceholder')
        : t('productFormTitlePlaceholder')
  }, [isFashionProduct, isSneakersProduct, t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormTitleLabel')}
      name="title"
      placeholder={placeholder}
      required={true}
      value={title}
      onChange={onChange}
    />
  )
})

export default ProductTitleField
